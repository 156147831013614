<template>
  <AccrualList/>
</template>

<script>
import AccrualList from "@/views/erp/AccrualList.vue";

export default {
  components: {
    AccrualList,
  }
}
</script>